import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import BEMHelper from 'react-bem-helper';

import { Carousel } from '../../common/carousel';
import { Content } from '../../common/content';
import './history.scss';

const bem = new BEMHelper({
  name: 'content-history',
});

export const History = ({
  id, headline, description, milestones,
}) => {
  const [selected, setSelected] = useState(0);
  const onScroll = useCallback(({ index: [at] }) => setSelected(at), [setSelected]);

  return (
    <Content id={id} {...bem()} useOwnSpacing>
      <div {...bem('introduction')}>
        <h2 {...bem('headline')}>{headline}</h2>
        <p {...bem('description')}>{description}</p>
      </div>
      <Carousel {...bem('milestones')} tagName="ol" onScroll={onScroll}>
        {milestones.map((milestone, index) => (
          <li key={milestone.slug} {...bem('milestone')}>
            <div {...bem('milestone__item')} key={milestone.slug}>
              <time
                {...bem(
                  'milestone__time',
                  { 'is-selected': (index === selected) },
                )}
                dateTime={milestone.datetime}
              >
                {milestone.title}
              </time>
              <p {...bem('milestone__description')}>
                {milestone.description}
              </p>
            </div>
          </li>
        ))}
      </Carousel>
    </Content>
  );
};

History.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  description: string.isRequired,
  milestones: arrayOf(shape({
    slug: string.isRequired,
    title: string.isRequired,
    datetime: string.isRequired,
    description: string.isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment HistoryContentFragment on CraftGQL_pageContents_history_BlockType {
    id
    headline
    description
    milestones {
      ...on CraftGQL_milestones_historyMilestone_Entry {
        slug
        title
        datetime: historyDate
        description: historyDescription
      }
    }
  }
`;
